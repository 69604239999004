import './projectViewer.scss';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getProject } from '../../api';
import { REPOSITORY_SYNC_INTERVAL_IN_MINUTES } from '../../constants';
import { useAppContext } from '../../hooks';
import { Project } from '../../models';
import { getMinutesAgo, printRelativeDate } from '../../util';
import { Tab, Tabs } from '../common';
import { AssetTab, DashboardTab, DocsTab, ProjectDetailsTab } from './tabs';

export interface ProjectTabProps {
    project: Project;
    setProject: (project: Project) => void;
    setBadge?: (badge: number) => void;
}

export const ProjectViewer = () => {
    const { projectKey } = useParams();
    const [project, setProject] = useState<Project | undefined>(undefined);
    const [filesBadge, setFilesBadge] = useState<number>(0);
    const [designsBadge, setDesignsBadge] = useState<number>(0);
    const [materialSelectionBadge, setMaterialSelectionBadge] = useState<number>(0);
    const [bidsBadge, setBidsBadge] = useState<number>(0);

    const { user } = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (projectKey) {
            if (!user?.roles.includes('admin') && !user?.projects?.some(p => p.key === projectKey)) {
                navigate('/', { replace: true });
            } else {
                getProject(projectKey!).then(project => {
                    if (project) {
                        setProject(project);
                        if (!project.lastRepositorySync || getMinutesAgo(project.lastRepositorySync) || 0 > REPOSITORY_SYNC_INTERVAL_IN_MINUTES) {
                            setTimeout(() => {
                                getProject(projectKey).then(setProject);
                            }, 10000);
                        }
                    }
                });
            }
        }
    }, [projectKey]);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        });
    }, [window.location.hash]);

    if (!project) return <div>Loading Project...</div>;

    return <>
        <div className='position-absolute text-end'
            style={{ top: '1rem', right: '2rem' }}
            title={project.lastRepositorySync ? `Last synced ${printRelativeDate(project.lastRepositorySync)}` : ''}>
            <div className='fs-4'>
                {project.address.street}
            </div>
            <div className='text-muted'>
                {project.ownerName} | {project.description}
            </div>
        </div>
        <div style={{ margin: '-1rem -1rem' }}>
            <Tabs>
                <></>
                <Tab title="Project Home">
                    <DashboardTab project={project} setProject={setProject} />
                </Tab>
                {
                    project.specificationsQuestionnaireId ?
                        <Tab title="Specifications">
                            <ProjectDetailsTab project={project} setProject={setProject} questionnaireType='Design Specifications' />
                        </Tab>
                        : <></>
                }
                <Tab title="Files" badge={filesBadge}>
                    <DocsTab project={project} setProject={setProject} setBadge={setFilesBadge} />
                </Tab>
                {
                    !!project.designCount ?
                        <Tab title="Designs" badge={designsBadge}>
                            <AssetTab
                                project={project}
                                setProject={setProject}
                                setBadge={setDesignsBadge}
                                assetType='Design'
                                enabledActions={{
                                    approve: true,
                                    requestChanges: true,
                                    notes: true
                                }}
                                facilitatorTitle='Designer'
                                promptForNotesOnApproval={true} />
                        </Tab> : <></>
                }
                {
                    !!project.materialSelectionCount ?
                        <Tab title="Material Selections" badge={materialSelectionBadge}>
                            <AssetTab
                                project={project}
                                setProject={setProject}
                                setBadge={setMaterialSelectionBadge}
                                assetType='Material Selection'
                                enabledActions={{
                                    approve: true,
                                    notes: true
                                }}
                                facilitatorTitle='Designer' />
                        </Tab> : <></>
                }
                {
                    !!project.bidCount ?
                        <Tab title="Bids" badge={bidsBadge}>
                            <AssetTab
                                project={project}
                                setProject={setProject}
                                setBadge={setBidsBadge}
                                assetType='Bid'
                                enabledActions={{
                                    approve: true
                                }}
                                facilitatorTitle='Estimator' />
                        </Tab> : <></>
                }
            </Tabs>
        </div>
    </>;
}