import { useEffect, useRef, useState } from 'react';
import { ellipsis, printRelativeDate } from '../../../util';
import { Blocker, Card, FileValue, HamburgerMenu, MenuItem } from '../../common';
import { ProjectTabProps } from '../projectViewer';
import { addProjectFilesFolder, deleteProjectFile, moveOrRenameProjectFile, getProjectFileFolder, uploadProjectFiles } from '../../../api';
import { FILE_ICON_URL, FOLDER_ICON_URL } from '../../../constants';
import { ProjectResource } from '../../../models';
import { useDialog } from '../../../hooks';

export const DocsTab = ({ project, setProject }: ProjectTabProps) => {
    const [folder, setFolder] = useState<ProjectResource>();
    const [isBlockingProcess, setIsBlockingProcess] = useState<boolean>(false);
    const [isNonBlockingProcess, setIsNonBlockingProcess] = useState<boolean>(false);
    const fileUpload = useRef<HTMLInputElement>(null);
    const [menuItems, setMenuItems] = useState<MenuItem[] | undefined>();
    const [selectedResource, setSelectedResource] = useState<ProjectResource>();
    const [isEditingName, setIsEditingName] = useState<boolean>(false);
    const [draggedResourceId, setDraggedResourceId] = useState<string | undefined>(undefined);
    const [dropZoneResourceId, setDropzoneResourceId] = useState<string | undefined>(undefined);

    const { alert, prompt, confirm } = useDialog();

    useEffect(() => {
        getProjectFileFolder(project.id).then(setFolder);
    }, [project.id]);

    useEffect(() => {
        if (folder?.id === selectedResource?.id) {
            setSelectedResource(undefined);
        }
    }, [folder, selectedResource]);

    useEffect(() => {
        if (selectedResource) {
            let _menuItems:MenuItem[] = [];
            if (!selectedResource.isFolder) {
                _menuItems.push({
                    label: 'Download',
                    iconClass: 'fa fa-download',
                    onClick: () => window.open(selectedResource.downloadUrl || selectedResource.webUrl, '_blank')
                });
                _menuItems.push({ type: 'divider' });
            }
            if (selectedResource.allowRename) {
                _menuItems.push({
                    label: 'Rename',
                    iconClass: 'fa fa-edit',
                    onClick: async () => {
                        const name = await prompt({ message: `What would you like to rename the ${selectedResource.isFolder ? 'folder' : 'file'} to?`, defaultValue: selectedResource.name, allowCancel: true });
                        if (name) {
                            setIsBlockingProcess(true);
                            moveOrRenameProjectFile(project.id, selectedResource.id, { name })
                                .then(resource => {
                                    if (resource) {
                                        if (selectedResource!.id === folder!.id) {
                                            setFolder(resource);
                                        } else {
                                            setFolder({ ...folder!, children: folder!.children!.map(c => c.id === resource.id ? resource : c) });
                                        }
                                        setSelectedResource(resource);    
                                    }
                                })
                                .finally(() => setIsBlockingProcess(false));
                        } else if (name === '') {
                            alert('Name is required.');
                        }
                    }
                });
            }
            if (selectedResource.allowDelete) {
                _menuItems.push({
                    label: 'Delete',
                    iconClass: 'fa fa-trash',
                    onClick: async () => {
                        if (await confirm(`Are you sure you want to delete ${selectedResource.name}?`)) {
                            setIsBlockingProcess(true);
                            deleteProjectFile(project.id, selectedResource.id)
                                .then(() => {
                                    if (selectedResource.isFolder && folder?.id === selectedResource.id) {
                                        getProjectFileFolder(project.id, selectedResource.parentId).then(setFolder);
                                    } else {
                                        setFolder({ ...folder!, children: folder?.children?.filter(c => c.id !== selectedResource.id) });
                                    }
                                    setSelectedResource(undefined);
                                })
                                .finally(() => setIsBlockingProcess(false));
                        }
                    }
                });
            }

            setMenuItems(_menuItems);
        } else {
            setMenuItems(undefined);
        }
    }, [selectedResource]);

    const openFolder = (id: string) => {
        if (isNonBlockingProcess) return;
        setIsNonBlockingProcess(true);
        getProjectFileFolder(project.id, id).then(x => {
            setFolder(x);
            setIsNonBlockingProcess(false);
        });
    }

    const addFolder = async () => {
        const name = await prompt({ message: 'What would you like to name the folder?', allowCancel: true });
        if (name) {
            if (folder?.children?.some(c => c.name === name)) {
                alert('A folder with that name already exists. Please choose a different name.');
                return;
            }
            setIsBlockingProcess(true);
            const addition = await addProjectFilesFolder(project.id, { name }, folder?.id);
            setFolder({ ...folder!, children: [...folder!.children || [], ...[addition]] });
            setSelectedResource(addition);
            setIsBlockingProcess(false);
        } else if (name === '') {
            alert('Name is required.');
        }
    };

    const uploadFiles = (files: FileList | null) => {
        if (files && files.length > 0) {
            setIsBlockingProcess(true);
            const newFiles: FileValue[] = [];

            const completeUpload = async () => {
                const additions = await uploadProjectFiles(project.id, newFiles, folder!.id);
                setFolder({ ...folder!, children: [...folder!.children || [], ...additions] });
                if (additions.length === 1) {
                    setSelectedResource(additions[0]);
                } else {
                    setSelectedResource(undefined);
                }
                setIsBlockingProcess(false);
            };

            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onload = async (event) => {
                    const file = {
                        folderId: folder!.id,
                        name: files[i].name,
                        mimeType: files[i].type,
                        content: event.target?.result as string,
                        size: files[i].size
                    };
                    newFiles.push(file);

                    if (newFiles.length === files.length) {
                        await completeUpload();
                    }
                };

                reader.readAsDataURL(files[i]);
            }
        }
    };

    const moveResource = (resourceId: string, folderId: string) => {
        setIsBlockingProcess(true);
        moveOrRenameProjectFile(project.id, resourceId, { folderId })
            .then(() => {
                setFolder({ ...folder!, children: folder?.children!.filter(c => c.id !== resourceId) });
                setSelectedResource(undefined);
            })
            .finally(() => setIsBlockingProcess(false));
    };

    if (!folder) return <></>;

    return <Blocker block={isBlockingProcess}>
        <div className={(isNonBlockingProcess ? 'wait' : '')}>
            <div className="row">
                <div className='col-10'>
                    <p>
                        Files related to your project are stored here. Use the 'Upload' button to get started...
                    </p>
                </div>
                <div className="col-2 text-end">
                    <button className="btn btn-outline-secondary mb-4"
                        title="New Folder"
                        onClick={() => addFolder()}
                        disabled={isBlockingProcess}>
                        <i className='fa fa-folder-plus'></i>
                    </button>
                    <button type='button'
                        className="btn btn-primary mb-4 ms-2 position-relative"
                        onClick={() => fileUpload.current?.click()}
                        disabled={isBlockingProcess}>
                        Upload File
                    </button>
                    <input type="file"
                        onChange={(e) => uploadFiles(e.target.files)}
                        style={{ display: 'none' }}
                        multiple={true}
                        accept="*"
                        ref={fileUpload} />
                </div>
            </div>
            <div className='row'>
                <div className="col-9">
                    <div className='box file-explorer'>
                        <div className='row mx-1'>
                            {
                                !!folder.ancestors?.length &&
                                <nav className='folder-navigation'>
                                    <ul className="breadcrumb">
                                        <li className='breadrcrumb-item'>
                                            <i className='fa fa-regular fa-folder-open me-2'></i>
                                        </li>
                                        {
                                            folder.ancestors?.map((a, i) =>
                                                <li key={i} className={'breadcrumb-item' + (a.id === dropZoneResourceId ? ' dropzone' : '')}
                                                    onDragOver={e => {
                                                        e.preventDefault();
                                                        e.dataTransfer.dropEffect = 'move';
                                                    }}
                                                    onDragEnter={() => {
                                                        setDropzoneResourceId(a.id);
                                                    }}
                                                    onDrop={e => {
                                                        moveResource(draggedResourceId!, a.id);
                                                        setDraggedResourceId(undefined);
                                                        setDropzoneResourceId(undefined);
                                                        e.preventDefault();
                                                    }}>
                                                    <a className="link" onClick={() => openFolder(a.id)}>{a.name}</a>
                                                </li>)
                                        }
                                        <li className="breadcrumb-item active">{folder.name}</li>
                                    </ul>
                                </nav>
                            }
                            {
                                !folder.children?.length &&
                                <div className='col-12 text-center text-muted'>
                                    This folder is empty.
                                </div>
                            }
                            {
                                folder.children?.map(r => {
                                    return <div key={r.id}
                                        draggable={r.allowMove}
                                        onDragStart={e => {
                                            setDraggedResourceId(r.id);
                                            e.dataTransfer.setDragImage(e.currentTarget, 1, 1);
                                        }}
                                        onDragEnd={() => {
                                            setDraggedResourceId(undefined);
                                            setDropzoneResourceId(undefined);
                                        }}
                                        onDragOver={e => {
                                            if (!r.isFolder || r.id === draggedResourceId) {
                                                e.dataTransfer.dropEffect = 'none';
                                            }
                                            e.preventDefault();
                                        }}
                                        onDragEnter={() => {
                                            if (r.isFolder && r.id !== draggedResourceId) {
                                                setDropzoneResourceId(r.id);
                                            } else {
                                                setDropzoneResourceId(undefined);
                                            }
                                        }}
                                        onDrop={e => {
                                            if (r.isFolder) {
                                                moveResource(draggedResourceId!, r.id);
                                                setDraggedResourceId(undefined);
                                                setDropzoneResourceId(undefined);
                                                e.preventDefault();
                                            }
                                        }}
                                        className={`col-2 mid-center ${(selectedResource?.id === r.id ? 'selected' : '')} ${selectedResource?.allowMove ? 'draggable' : ''} ${draggedResourceId === r.id ? 'dragging' : ''} ${dropZoneResourceId === r.id ? 'dropzone' : ''}`}>
                                        <div className='btn btn-link mid-center flex-column py-3'
                                            onClick={() => {
                                                setSelectedResource(r);
                                            }}
                                            onDoubleClick={() => {
                                                if (isEditingName) return;
                                                if (r.isFolder) {
                                                    openFolder(r.id);
                                                } else {
                                                    window.open(r.downloadUrl || r.webUrl, '_blank');
                                                }
                                            }}>
                                            <div style={{ width: '100px', height: '100px' }} className='mid-center'>
                                                <img src={r.thumbnailUrl || r.iconUrl || (r.isFolder ? FOLDER_ICON_URL : FILE_ICON_URL)} alt={r.name} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                            </div>
                                            <div title={r.name}
                                                contentEditable={selectedResource?.id === r.id && isEditingName}
                                                suppressContentEditableWarning={true}
                                                className={'text-center fs-6 mt-1' + (selectedResource?.id === r.id && isEditingName ? ' form-control' : '')}
                                                onClick={e => {
                                                    if (r.id === selectedResource?.id && r.allowRename && !isEditingName) {
                                                        setIsEditingName(true);
                                                        const target = e.currentTarget;
                                                        target.innerText = r.name;
                                                        setTimeout(() => {
                                                            const range = document.createRange();
                                                            range.selectNodeContents(target);
                                                            const selection = window.getSelection();
                                                            selection!.removeAllRanges();
                                                            selection!.addRange(range);
                                                        });
                                                    }
                                                }}
                                                onBlur={e => {
                                                    const name = e.currentTarget.innerText;
                                                    e.currentTarget.innerText = ellipsis(name, 14);
                                                    setIsEditingName(false);
                                                    if (name !== r.name) {
                                                        setIsBlockingProcess(true);
                                                        moveOrRenameProjectFile(project.id, selectedResource!.id, { name })
                                                            .then(resource => {
                                                                if (resource) {
                                                                    if (selectedResource!.id === folder!.id) {
                                                                        setFolder(resource);
                                                                    } else {
                                                                        setFolder({ ...folder!, children: folder.children!.map(c => c.id === resource.id ? resource : c) });
                                                                    }
                                                                    setSelectedResource(resource);
                                                                }
                                                            })
                                                            .finally(() => setIsBlockingProcess(false));                            
                                                    }
                                                }}>
                                                { ellipsis(r.name, 14) }
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                {
                    !selectedResource && <div className="col-3">
                        <div className='text-center text-muted'>
                            Select a file to view details.
                        </div>
                    </div>
                }
                {
                    selectedResource && <div className="col-3">
                        <Card title='File Details'>
                            <div className="metadata">
                                <div className='mid-center'>
                                    <div className='mid-center' style={{ width: '150px', height: '150px' }}>
                                        <img src={selectedResource.thumbnailUrl || selectedResource.iconUrl || (selectedResource.isFolder ? FOLDER_ICON_URL : FILE_ICON_URL)}
                                            alt={selectedResource.name}
                                            style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                    </div>
                                </div>
                                <hr className='mt-4 mb-0' />
                                <div>
                                    <label>Name</label>
                                    <div>{selectedResource.name}</div>
                                </div>
                                <label>File Type</label>
                                <div>{selectedResource.fileType}</div>
                                {
                                    selectedResource.lastUpdate &&
                                    <>
                                        <div>
                                            <label>Last Update</label>
                                            <div>{printRelativeDate(selectedResource.lastUpdate?.date)}</div>
                                        </div>
                                        {
                                            selectedResource.lastUpdate.user &&
                                            <div className='mt-2'>
                                                <img src={selectedResource.lastUpdate.user.avatarUrl}
                                                    className='avatar'
                                                    width="50px"
                                                    alt={selectedResource.lastUpdate.user.name} />
                                                <a href={"mailto:" + selectedResource.lastUpdate.user.email}>
                                                    {ellipsis(selectedResource.lastUpdate.user.name, 30)}
                                                </a>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                            {
                                !!menuItems?.length &&
                                <div className='text-center mt-4'>
                                    <HamburgerMenu
                                        type='h-ellipsis'
                                        items={menuItems} />
                                </div>
                            }
                        </Card>
                    </div>
                }
            </div>
        </div>
    </Blocker>
};
