import ReactDOM from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'highlight.js/styles/default.css';

// Local imports

import './styles/fonts.scss';
import './styles/variables.scss';
import './styles/global.scss';

import './util/axiosInterceptor';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <App />
);

reportWebVitals();
