import { NavLink } from "react-router-dom";
import './hamburgerMenu.scss';
import { MenuItem } from "../models";

export interface HamburgerMenuProps {
  type: 'hamburger' | 'v-ellipsis' | 'h-ellipsis';
  header?: string;
  items: MenuItem[];
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const HamburgerMenu = ({ header, type, items, size = 'md' }: HamburgerMenuProps) => <div className="hamburger-menu">
  <div className="dropdown">
    <a className={`btn dropdown-toggle btn-${size}`} href="#" role="button" data-bs-toggle="dropdown">
      { type === 'hamburger' && <i className='fa fa-bars fa-2x'></i> }
      { type === 'h-ellipsis' && <i className='fa fa-ellipsis-h fa-2x'></i> }
      { type === 'v-ellipsis' && <i className='fa fa-ellipsis-v fa-2x'></i> }
    </a>
    <ul className="dropdown-menu dropdown-menu-end">
      {
          header && <li className="dropdown-header"><span>{header}</span></li>
      }
      {
        items.map((item, index) => <li key={index}>
          {
            (item.type === 'option' || !item.type) && item.path &&
            <NavLink className="dropdown-item" to={item.path} onClick={item.onClick} end>
              {item.iconClass && <i className={item.iconClass}></i>}
              {item.label}
            </NavLink>
          }
          {
            (item.type === 'option' || !item.type) && !item.path &&
            <span className="dropdown-item" onClick={item.onClick} style={{ cursor: 'pointer' }}>
              {item.iconClass && <i className={item.iconClass}></i>}
              {item.label}
            </span>
          }
          {
            item.type === 'divider' && <hr className='dropdown-divider' />
          }
        </li>)
      }
    </ul>
  </div>
</div>;