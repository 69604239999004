import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';

import './projectList.scss';
import { deleteProject, forceProjectSync, listProjectTemplates, listProjects, listQuestionSets, saveProject } from '../../../api';
import { Project, ProjectTemplate, QuestionSet } from '../../../models';
import { Blocker, Card, CheckboxListDropdown, Drawer, HamburgerMenu, Modal } from '../../common';
import { generateTempId } from '../../../util';
import { Link } from 'react-router-dom';
import { useDialog } from '../../../hooks';

export const ProjectList = () => {
    const [projects, setProjects] = useState<Project[] | undefined>(undefined);
    const [projectTemplates, setProjectTemplates] = useState<ProjectTemplate[] | undefined>(undefined);
    const [questionSets, setQuestionSets] = useState<QuestionSet[] | undefined>(undefined);

    const navigate = useNavigate();
    const { confirm } = useDialog();

    const [search, setSearch] = useState<string>('');

    const [editingProject, setEditingProject] = useState<Project | undefined>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isNonBlockingProcess, setIsNonBlockingProcess] = useState<boolean>(false);

    const searchPattern = new RegExp(search, 'i');

    useEffect(() => {
        listProjects().then(setProjects);
        listProjectTemplates().then(setProjectTemplates);
        listQuestionSets().then(setQuestionSets);
    }, []);

    const newProject = () => {
        const project: Project = {
            id: generateTempId(),
            ownerName: '',
            address: {
                street: '',
                city: '',
                state: 'TX',
                postal: ''
            },
            fiscalYear: new Date().getFullYear(),
            referenceNumber: '',
            description: '',
            specificationsSettings: {
                allowEdits: true,
                questionSetIds: []
            },
            userEmails: [],
            templateId: '',
            completedStepIds: []
        };

        setEditingProject(project);
    };

    const completeProjectEdit = () => {
        setIsSaving(true);
        saveProject(editingProject!).then((project) => {
            if (project) {
                const _projects = [...projects!];
                const index = _projects.findIndex(x => x.id === project!.id);
                if (index > -1) {
                    _projects[index] = project;
                } else {
                    _projects.push(project!);
                }
                setProjects(_projects);
                setEditingProject(undefined);
            }
            setIsSaving(false);
        });
    }

    const onDelete = async (project: Project) => {
        if (await confirm('Are you sure you want to delete this project?')) {
            deleteProject(project.id).then(() => {
                setProjects(projects!.filter(x => x.id !== project.id));
            });
        };
    }

    const AssetStat = ({ count, approvedCount }: { count: number | undefined, approvedCount: number | undefined }) => {
        count = count || 0;
        approvedCount = approvedCount || 0;
        if (count === 0) return <>-</>;
        if (approvedCount === count) return <i className='fa fa-check'></i>;
        return <span className='badge text-muted rounded-pill'>{count - approvedCount}</span>;
    };

    if (!projects || !projectTemplates || !questionSets) return <div>Loading Projects...</div>;

    return <div className={isNonBlockingProcess ? 'wait' : ''}>
        <div className="float-end">
            <button type="button"
                className="btn btn-primary"
                onClick={() => newProject()}>
                New Project
            </button>
        </div>
        <h1>Current Projects</h1>
        <div className="clearfix"></div>
        {
            projects?.length === 0 &&
            <div className='box mt-4 text-center'>
                There are no projects yet. Click the "New Project" button to get started.
            </div>
        }
        {
            projects?.length > 0 &&
            <>
                <div className='my-4 col-4'>
                    <div className="input-group">
                        <input type="text"
                            className="form-control"
                            placeholder="Search"
                            value={search}
                            onChange={e => setSearch(e.target.value)} />
                        {
                            !!search && <button type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => setSearch('')}>
                                <i className='fa fa-times'></i>
                            </button>
                        }
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Project #</th>
                            <th>Owner Name</th>
                            <th>Address</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th className='text-center'>
                                Specifications
                            </th>
                            <th className='text-center'>Designs</th>
                            <th className='text-center'>Material Selections</th>
                            <th className='text-center'>Bids</th>
                            <th className='text-center'>Status</th>
                            <th style={{ width: 0 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            projects.filter(x => !search || x.ownerName.match(searchPattern) || x.address.street.match(searchPattern))
                                .map(project => {
                                    return <tr key={project.id}>
                                        <td>{project.referenceNumber}</td>
                                        <td>
                                            <Link to={`/admin/projects/${project.key}`}
                                                title="View">
                                                {project.ownerName}
                                            </Link>
                                        </td>
                                        <td>
                                            {project.address.street}<br />
                                            {project.address.city}, {project.address.state} {project.address.postal}
                                        </td>
                                        <td>
                                            {projectTemplates.find(x => x.id === project.templateId)?.name}
                                        </td>
                                        <td>{project.description}</td>
                                        <td className='text-center'>
                                            {
                                                projectTemplates.find(x => x.id === project.templateId)?.questionnaires?.includes('Design Specifications')
                                                    ? !!project.specificationsSubmissionDate ? <i className='fa fa-check'></i> : <>-</>
                                                    : <>N/A</>
                                            }
                                        </td>
                                        <td className='text-center'>
                                            <AssetStat count={project.designCount} approvedCount={project.approvedDesignCount} />
                                        </td>
                                        <td className='text-center'>
                                            <AssetStat count={project.materialSelectionCount} approvedCount={project.approvedMaterialSelectionCount} />
                                        </td>
                                        <td className='text-center'>
                                            <AssetStat count={project.bidCount} approvedCount={project.approvedBidCount} />
                                        </td>
                                        <td className='text-center'>
                                            <span className="badge text-bg-dark">
                                                {project.status}
                                            </span>
                                        </td>
                                        <td className='text-end'>
                                            <HamburgerMenu type={'v-ellipsis'} size='sm' items={[
                                                { label: 'View', iconClass: 'fa fa-fw fa-eye', onClick: () => navigate(`/admin/projects/${project.key}`) },
                                                { type: 'divider' },
                                                {
                                                    label: 'Force Sync', iconClass: 'fa fa-fw fa-right-left', onClick: () => {
                                                        setIsNonBlockingProcess(true);
                                                        forceProjectSync(project.id).then(() => {
                                                            setIsNonBlockingProcess(false);
                                                        });
                                                    }
                                                },
                                                { label: 'Edit', iconClass: 'fa fa-fw fa-pencil', onClick: () => setEditingProject(project) },
                                                { label: 'Delete', iconClass: 'fa fa-fw fa-trash', onClick: () => onDelete(project) },
                                            ]} />
                                        </td>
                                    </tr>;
                                })
                        }
                    </tbody>
                </table>
            </>
        }
        {
            editingProject &&
            <ProjectEditor
                project={editingProject}
                projectTemplates={projectTemplates}
                questionSets={questionSets}
                setProject={setEditingProject}
                onSave={() => completeProjectEdit()}
                onCancel={() => setEditingProject(undefined)}
                isSaving={isSaving} />
        }
    </div>;
}

export interface ProjectEditorProps {
    project: Project;
    projectTemplates: ProjectTemplate[];
    questionSets: QuestionSet[];
    setProject: (project: Project) => void;
    onSave: () => void;
    onCancel: () => void;
    isSaving?: boolean;
}

export const ProjectEditor = ({ project, projectTemplates, questionSets, setProject, onSave, onCancel, isSaving }: ProjectEditorProps) => {
    const [selectedProjectTemplate, setSelectedProjectTemplate] = useState<ProjectTemplate | undefined>(undefined);
    const [filteredQuestionSets, setFilteredQuestionSets] = useState<QuestionSet[] | undefined>(undefined);
    const [editingUsers, setEditingUsers] = useState(false);

    const { alert } = useDialog();

    useEffect(() => {
        if (project?.templateId && projectTemplates) {
            setSelectedProjectTemplate(projectTemplates.find(x => x.id === project.templateId));
        } else {
            setSelectedProjectTemplate(undefined);
        }
    }, [project?.templateId, projectTemplates]);

    useEffect(() => {
        if (project?.templateId && questionSets) {
            const _filteredQuestionSets = questionSets.filter(x => x.allowForAllProjectTemplates || x.projectTemplateIds?.includes(project.templateId));
            setFilteredQuestionSets(_filteredQuestionSets);

            let _project = { ...project };

            // add any required question sets that are not already included
            const requiredQuestionSetIds = _filteredQuestionSets.filter(x => x.isRequired).map(x => x.id);
            requiredQuestionSetIds.forEach(x => {
                if (!_project.specificationsSettings.questionSetIds.includes(x)) {
                    _project.specificationsSettings.questionSetIds.push(x);
                }
            });

            setProject(_project);
        } else {
            setFilteredQuestionSets(undefined);
        }
    }, [project?.templateId, questionSets]);

    const validate = () => {
        // TODO: Make this a framework!

        if (!project?.ownerName) {
            alert('Owner Name is required.');
            return false;
        }
        if (!project?.address.street) {
            alert('Street is required.');
            return false;
        }
        if (!project?.address.city) {
            alert('City is required.');
            return false;
        }
        if (!project?.address.state) {
            alert('State is required.');
            return false;
        }
        if (!project?.address.postal) {
            alert('Postal is required.');
            return false;
        }
        if (!project?.referenceNumber) {
            alert('Project # is required.');
            return false;
        }
        if (!project?.description) {
            alert('Description is required.');
            return false;
        }
        if (!project?.fiscalYear) {
            alert('Fiscal Year is required.');
            return false;
        }
        if (!project?.templateId) {
            alert('Project Template is required.');
            return false;
        }

        return true;
    };

    return <Drawer title='Project Editor' size='xl'
        confirmText='Save'
        onConfirm={() => {
            if (validate()) {
                onSave();
            }
        }}
        onCancel={() => onCancel()}>
        <Blocker block={isSaving}>
            <div className="box">
                <div className="row">
                    <div>
                        <button
                            className='btn btn-outline-primary btn-sm float-end'
                            onClick={() => setEditingUsers(true)}>
                            Manage Users ({project?.userEmails?.length || 0})
                        </button>
                        <h4 className='mb-3'>Property / Owner Data</h4>
                        <div className="clearfix"></div>
                    </div>
                    <div className="form-group col-12">
                        <label>Owner Name</label>
                        <input type='text'
                            className="form-control"
                            value={project?.ownerName}
                            onChange={e => setProject({ ...project, ownerName: e.target.value })} />
                    </div>

                    <div className="form-group col-12">
                        <label>Street</label>
                        <input type='text'
                            className="form-control"
                            value={project?.address.street}
                            onChange={e => setProject({ ...project, address: { ...project.address, street: e.target.value } })} />
                    </div>
                    <div className="form-group col-7">
                        <label>City</label>
                        <input type='text'
                            className="form-control"
                            value={project?.address.city}
                            onChange={e => setProject({ ...project, address: { ...project.address, city: e.target.value } })} />
                    </div>
                    <div className="form-group col-2">
                        <label>State</label>
                        <input type='text'
                            className="form-control"
                            value={project?.address.state}
                            onChange={e => setProject({ ...project, address: { ...project.address, state: e.target.value } })} />
                    </div>
                    <div className="form-group col-3">
                        <label>Postal</label>
                        <input type='text'
                            className="form-control"
                            value={project?.address.postal}
                            onChange={e => setProject({ ...project, address: { ...project.address, postal: e.target.value } })} />
                    </div>
                </div>
            </div>
            <div className='box'>
                <div className='row'>
                    <h4 className="mb-3">Project Information</h4>
                    <div className="form-group col-4">
                        <label>Project #</label>
                        <input type='text'
                            className="form-control"
                            value={project?.referenceNumber}
                            onChange={e => setProject({ ...project, referenceNumber: e.target.value })} />
                    </div>
                    <div className="form-group col-6">
                        <label>Project Description</label>
                        <input type='text'
                            className="form-control"
                            value={project?.description}
                            onChange={e => setProject({ ...project, description: e.target.value })} />
                    </div>
                    <div className="form-group col-2">
                        <label>Fiscal Year</label>
                        <input type='text'
                            className="form-control"
                            value={project?.fiscalYear}
                            onChange={e => setProject({ ...project, fiscalYear: +e.target.value })} />
                    </div>
                    <div className='form-group col-4'>
                        <label>Project Template</label>
                        <select className='form-control'
                            value={project?.templateId}
                            onChange={e => setProject({ ...project, templateId: e.target.value })}>
                            <option></option>
                            {
                                projectTemplates?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                            }
                        </select>
                    </div>
                    <div className="col-8">
                        {
                            selectedProjectTemplate &&
                            <CheckboxListDropdown
                                label='Steps Completed'
                                options={selectedProjectTemplate?.steps.map(x => ({ label: x.name, value: x.id, isDisabled: !!x.trigger })) || []}
                                values={project?.completedStepIds || []}
                                onChange={x => setProject({ ...project, completedStepIds: x })}
                                columns={1} />
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {
                        selectedProjectTemplate && selectedProjectTemplate.questionnaires?.includes('Design Specifications') &&
                        <div className='box'>
                            <div className='row'>
                                <div className="col-12">
                                    <h4 className='mb-3'>Design Specifications</h4>
                                </div>
                                <div className="col-12">
                                    <div className='from-group'>
                                        <CheckboxListDropdown
                                            label=''
                                            options={filteredQuestionSets?.map(x => ({ label: x.name, value: x.id, isDisabled: x.isRequired })) || []}
                                            values={project?.specificationsSettings.questionSetIds || []}
                                            onChange={x => setProject({ ...project, specificationsSettings: { ...project.specificationsSettings, questionSetIds: x } })}
                                            columns={1} />
                                    </div>
                                    <div className='from-group'>
                                        <label>
                                            <input type='checkbox'
                                                checked={project?.specificationsSettings.allowEdits}
                                                onChange={e => setProject({ ...project, specificationsSettings: { ...project.specificationsSettings, allowEdits: e.target.checked } })} />
                                            Editing Enabled
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {
                editingUsers &&
                <Modal title='Project Users' onConfirm={() => {
                    setProject({ ...project, userEmails: project.userEmails.filter(x => x !== '') });
                    setEditingUsers(false);
                }}>
                    <UserEmailsEntry
                        userEmails={project.userEmails || []}
                        setUserEmails={x => setProject({ ...project, userEmails: x })} />
                </Modal>
            }
        </Blocker>
    </Drawer>;
}

interface UserEmailsEntryProps {
    userEmails: string[];
    setUserEmails: (userEmails: string[]) => void;
}

const UserEmailsEntry = ({ userEmails, setUserEmails }: UserEmailsEntryProps) => {
    return <>
        <div className="text-muted mb-3 text-sm">
            Click below to add users to the project. Each user will receive an email invitation to join the project.
        </div>
        {
            !!userEmails.length && <div className="form-group">
                {userEmails.map((x, index) => <div key={index}>
                    <div className="input-group mb-2">
                        <input type="email"
                            className="form-control"
                            value={x}
                            onChange={e => {
                                const newUserEmails = [...userEmails];
                                newUserEmails[index] = e.target.value;
                                setUserEmails(newUserEmails);
                            }} />
                        <button type="button" className="input-group-text"
                            onClick={() => setUserEmails(userEmails.filter((_, i) => i !== index))}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                </div>)}
            </div>
        }
        <div className="text-center">
        <button type="button"
                onClick={() => setUserEmails([...userEmails, ''])}
                className="btn btn-outline-primary btn-sm"
                disabled={ userEmails[userEmails.length - 1] === '' }>
                {userEmails.length === 0 ? 'Add User' : 'Add Another User'}
            </button>
        </div>
    </>;
}
