import { ReactNode } from "react";
import "./blocker.scss";
import { BLOCKER_IMAGE } from "./blockerImage";

export interface BlockerProps {
  block: boolean | undefined;
  message?: string;
  children?: ReactNode;
}

export const Blocker = ({
  block,
  message,
  children,
}: BlockerProps): JSX.Element => (
  <>
    {block && (
      <div className="blocker" style={{ position: children ? 'absolute' : 'fixed' }}>
        <div className="loading">
          <img className="icon" src={BLOCKER_IMAGE} alt={message || "..."} />
          <span className="message">{message?.replace("...", "")}</span>
        </div>
      </div>
    )}
    {children}
  </>
);
