import { useEffect, useState } from "react";
import { getCurrentUser } from "./api";
import { useAppContext } from "./hooks";

export const AppLoader = ({ children }: { children: React.ReactNode }) => {
    const { user, setUser, isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!user && isAuthenticated()) {
            getCurrentUser().then(user => {
                if (user) {
                    setUser(user);
                }
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [ user ]);

    return isLoading ? <></> : <>{children}</>;
};