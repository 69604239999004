export interface ModalProps {
    onCancel?: () => void;
    onConfirm?: () => void;
    onEscape?: () => void;
    title?: string;
    confirmText?: string;
    children: React.ReactNode;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'fullscreen';
}

export const Modal = ({ onConfirm, onCancel, onEscape, confirmText = 'OK', title, children, size }: ModalProps) => {
    return (
        <div>
            <div className="modal-backdrop fade show" onClick={onEscape ? onEscape : () => { }}></div>
            <div className="modal show"
                style={{ display: 'block' }}
                tabIndex={-1}
                role="dialog">
                <div className={"modal-dialog" + (!!size ? ' modal-' + size : '')} role="document">
                    <div className="modal-content">
                        {
                            (title || onEscape) &&
                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title">
                                    {title}
                                </h5>
                                {onEscape && <button type="button" className="btn btn-close" onClick={onEscape}></button>}
                            </div>
                        }
                        <div className="modal-body">
                            {children}
                        </div>
                        {
                            (onConfirm || onCancel) &&
                            <div className="modal-footer bg-light">
                                {onCancel && <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>}
                                {onConfirm && <button type="button" className="btn btn-primary" onClick={onConfirm}>{confirmText}</button>}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};