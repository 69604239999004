import { useEffect, useState } from 'react';
import { CheckboxesElement, FileUploadElement, RadioButtonsElement, TextEntryElement } from '../shared';
import { listQuestionSets, saveProjectIntake } from '../../api';

import './intakeForm.scss';
import { Contact, ProjectIntake } from '../../models';
import { Blocker, Card, FormOption } from '../common';

export const IntakeForm = () => {
    const [questionSetOptions, setQuestionSetOptions] = useState<FormOption[] | undefined>(undefined);
    const [intake, setIntake] = useState<ProjectIntake>({
        isHomeowner: undefined,
        primaryContact: {
            name: '',
            phone: '',
            email: ''
        },
        secondaryContact: {
            name: '',
            phone: '',
            email: ''
        },
        address: {
            street: '',
            city: '',
            state: 'TX',
            postal: ''
        },
        lotSquareFootage: '',
        isNewConstruction: undefined,
        buildStatus: '',
        completedBuildStages: [],
        projectDescription: '',
        budget: '',
        plannedCompletionDate: '',
        scopeOfWork: [],
        additionalNotes: '',
        attachements: []
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        listQuestionSets().then(questionSets => {
            setQuestionSetOptions(questionSets.filter(x => !x.isRequired).map(x => ({ label: x.name, value: x.id })));
        });
    }, []);

    const submit = () => {
        setIsSubmitting(true);
        saveProjectIntake(intake).then(x => {
            setIntake(x);
            setIsSubmitting(false);
            setIsComplete(true);
        });
    }

    if (isComplete) return <div className='intake-form'>
        <div className='logo'>
            BLOUNT | DESIGNS
        </div>
        <div>
            <p className='lead text-center'>
                Thank you! We will be in contact soon! In the meantime, please checkout <a href="https://blountdesigns.com">blountdesigns.com</a> for inspiration.
            </p>
        </div>
    </div>;

    if (!questionSetOptions) return <></>

    return <div className='intake-form'>
        <Blocker block={isSubmitting} />
        <div className='logo'>
            BLOUNT | DESIGNS
        </div>
        <div style={{ height: '20rem', overflow: 'hidden' }}>
            <img style={{ marginTop: '-12.5rem' }} src="https://static.wixstatic.com/media/5f80cf_455a0333c48c4ab5af2761a3ba4f254d~mv2.jpg/v1/fill/w_1384,h_736,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/5f80cf_455a0333c48c4ab5af2761a3ba4f254d~mv2.jpg" alt="" />
        </div>
        <br />
        <h1>Request for Quote</h1>
        <p className='mb-4'>
            Welcome to Blount Designs! Please fill out the following form to the best of your ability. If you have any questions, please contact <a href="mailto:clayton@blountdesigns.com">clayton@blountdesigns.com</a>.
        </p>
        <Card title='About You'>
            <div className="row">
                <div className="col-12">
                    <RadioButtonsElement
                        config={{
                            prompt: 'Are you the homeowner?', columns: 12, options: [
                                { label: 'Yes', value: true },
                                { label: 'No', value: false }
                            ]
                        }}
                        value={intake.isHomeowner}
                        onValueChange={(value) => setIntake({ ...intake, isHomeowner: value })} />
                </div>
            </div>
            <ContactFields
                label='Primary Contact'
                contact={intake.primaryContact}
                onContactChange={(value) => setIntake({ ...intake, primaryContact: value })}
                collectCompanyInfo={intake.isHomeowner === false} />
            <ContactFields
                label='Secondary Contact (if applicable)'
                contact={intake.secondaryContact || { name: '', phone: '', email: '' }}
                onContactChange={(value) => setIntake({ ...intake, secondaryContact: value })}
                collectCompanyInfo={intake.isHomeowner === false} />
        </Card>
        <Card title='Property Information'>
            <div className="row">
                <div className="col-6">
                    <TextEntryElement
                        config={{ prompt: 'Street Address' }}
                        value={intake.address.street}
                        onValueChange={(value) => setIntake({ ...intake, address: { ...intake.address, street: value } })} />
                    <div className='row mb-4'>
                        <div className='col-7'>
                            <TextEntryElement
                                config={{ prompt: 'City' }}
                                value={intake.address.city}
                                onValueChange={(value) => setIntake({ ...intake, address: { ...intake.address, city: value } })} />
                        </div>
                        <div className='col-2'>
                            <TextEntryElement
                                config={{ prompt: 'State' }}
                                value={intake.address.state}
                                onValueChange={(value) => setIntake({ ...intake, address: { ...intake.address, state: value } })} />
                        </div>
                        <div className='col-3'>
                            <TextEntryElement
                                config={{ prompt: 'Postal' }}
                                value={intake.address.postal}
                                onValueChange={(value) => setIntake({ ...intake, address: { ...intake.address, postal: value } })} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-4">
                            <TextEntryElement
                                config={{ prompt: 'Lot Square Footage' }}
                                value={intake.lotSquareFootage}
                                onValueChange={(value) => setIntake({ ...intake, lotSquareFootage: value })} />
                        </div>
                    </div>
                </div>
                <div className='col-5 offset-1'>
                    <RadioButtonsElement
                        config={{
                            prompt: 'Is the property new construction?', columns: 4, options: [
                                { label: 'Yes', value: true },
                                { label: 'No', value: false }
                            ]
                        }}
                        value={intake.isNewConstruction}
                        onValueChange={(value) => setIntake({ ...intake, isNewConstruction: value })} />
                    <br />
                    {
                        intake.isNewConstruction && <>
                            <RadioButtonsElement
                                config={{ prompt: 'What is the status of the build?', columns: 3, options: ['In Planning', 'In Progress', 'Completed'] }}
                                value={intake.buildStatus}
                                onValueChange={(value) => setIntake({ ...intake, buildStatus: value })} />
                            <br />
                        </>
                    }
                    {
                        intake.isNewConstruction && intake.buildStatus === 'In Progress' && <>
                            <CheckboxesElement
                                config={{ prompt: 'What stages have been completed?', columns: 3, options: ['Lot Cleared', 'Slab Poured', 'Framing', 'Masonry', 'Flatwork'] }}
                                value={intake.completedBuildStages}
                                onValueChange={(value) => setIntake({ ...intake, completedBuildStages: value })} />
                        </>
                    }
                </div>
            </div>
        </Card>
        <Card title='Design / Project Details'>
            <div className='row mb-4'>
                <div className="col-12">
                    <TextEntryElement config={{ prompt: 'Briefly tell us about your project.', multiline: true }}
                        value={intake.projectDescription}
                        onValueChange={(value) => setIntake({ ...intake, projectDescription: value })} />
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-7'>
                    <RadioButtonsElement config={{ prompt: 'Estimated Budget', options: ['< $100K', '$100-250K', '$250-500K', '$500K-1M', '$1M+'], columns: 3 }}
                        value={intake.budget}
                        onValueChange={(value) => setIntake({ ...intake, budget: value })} />
                </div>
                <div className='col-4 offset-1'>
                    <TextEntryElement
                        config={{ prompt: 'When should this project be completed?' }}
                        value={intake.plannedCompletionDate}
                        onValueChange={(value) => setIntake({ ...intake, plannedCompletionDate: value })} />
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-12'>
                    <CheckboxesElement
                        config={{ prompt: 'Scope of Work (Check all that apply)', columns: 4, options: questionSetOptions }}
                        value={intake.scopeOfWork}
                        onValueChange={(value) => setIntake({ ...intake, scopeOfWork: value })} />
                </div>
            </div>
        </Card>
        <Card title='Other'>
            <div className='row'>
                <div className="col-12">
                    <TextEntryElement
                        config={{ prompt: 'Please provide any additional notes.', multiline: true }}
                        value={intake.additionalNotes}
                        onValueChange={(value) => setIntake({ ...intake, additionalNotes: value })} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <FileUploadElement
                        config={{ name: 'Attachments', prompt: 'Attachments (if applicable)', allowMultipleSelection: true, acceptedFileTypes: "*" }}
                        value={intake.attachements}
                        onValueChange={(value) => setIntake({ ...intake, attachements: value })} />
                </div>
            </div>
        </Card>
        <div>
            <button className='btn btn-primary btn-lg col-12 mb-5' onClick={() => submit()}>Submit</button>
        </div>
    </div>
};

interface ContactFieldsProps {
    label: string;
    contact: Contact;
    onContactChange: (contact: Contact) => void;
    collectCompanyInfo?: boolean;
}

const ContactFields = ({ label, contact, onContactChange, collectCompanyInfo }: ContactFieldsProps) => {
    return <>
        <h4>{label}</h4>
        <div className="row">
            <div className="col-4">
                <TextEntryElement
                    config={{ prompt: 'Name' }}
                    value={contact.name}
                    onValueChange={(value) => onContactChange({ ...contact, name: value })} />
            </div>
            <div className="col-4">
                <TextEntryElement
                    config={{ prompt: 'Phone' }}
                    value={contact.phone}
                    onValueChange={(value) => onContactChange({ ...contact, phone: value })} />
            </div>
            <div className="col-4">
                <TextEntryElement
                    config={{ prompt: 'Email Address' }}
                    value={contact.email}
                    onValueChange={(value) => onContactChange({ ...contact, email: value })} />
            </div>
            {
                !!collectCompanyInfo && <>
                    <div className='col-4'>
                        <TextEntryElement
                            config={{ prompt: 'Company' }}
                            value={contact.company}
                            onValueChange={(value) => onContactChange({ ...contact, company: value })} />
                    </div>
                    <div className='col-4'>
                        <TextEntryElement
                            config={{ prompt: 'Title' }}
                            value={contact.title}
                            onValueChange={(value) => onContactChange({ ...contact, title: value })} />
                    </div>
                </>
            }
        </div>
    </>
};