import axios from "axios";
import { HOST_MAP } from "../config";

const ACCESS_TOKEN_KEY = "accessToken";
const ACCESS_TOKEN_RESPONSE_HEADER = "X-Access-Token";

axios.interceptors.request.use(
    async (config) => {
        const apiEndpoint = `${window.location.protocol}//${HOST_MAP.get(window.location.host)}/`;
        config.url = apiEndpoint + config.url;

        // add authorization header to all requests
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        if (accessToken) {
            config.headers = config.headers || {};
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

axios.interceptors.response.use(
    async (response) => {
        // update token
        if (response?.headers?.get) {
            const accessToken = (response.headers as any)?.get(ACCESS_TOKEN_RESPONSE_HEADER);
            if (accessToken) {
                localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
            } else {
                localStorage.removeItem(ACCESS_TOKEN_KEY);
            }    
        }

        return response;
    },
    (error) => {
        let message = "";
        if (error.response) {
            message = `Error ${error.response.status}: ${error.response.statusText}`;

            if (error.response.data?.message) {
                message += `\n${error.response.data.message}`;
            }
        } else {
            message = error.message || "An unknown error occurred.";
        }

        alert(message);
        //Promise.reject(error);
    },
);