import { useEffect, useState } from 'react';
import { useAppContext } from '../../../hooks';
import { ProjectTabProps } from '../projectViewer';
import { ProjectStep, ProjectTemplate } from '../../../models';
import { getProjectTemplate, saveProject } from '../../../api';
import { Step, Stepper } from '../../common/stepper';
import { plural } from 'pluralize';

export const DashboardTab = ({ project, setProject }: ProjectTabProps) => {
    const { user } = useAppContext();
    const [projectTemplate, setProjectTemplate] = useState<ProjectTemplate>();

    useEffect(() => {
        if (project) {
            getProjectTemplate(project.templateId).then(setProjectTemplate);
        }
    }, [project]);


    const goToTab = (tabName: string) => {
        return (document.querySelector(`.nav-tabs a#${tabName}-tab`) as any)?.click();
    }

    if (!projectTemplate) {
        return <>Loading...</>;
    }

    const completedStepNumbers = Object.values(projectTemplate.steps)
        .filter(step => project.completedStepIds.includes(step.id))
        .map(step => step.number);

    const nextStepNumber = completedStepNumbers.length === projectTemplate.steps.length
        ? -1
        : completedStepNumbers.length > 0
            ? Math.max(...completedStepNumbers) + 1
            : 1;

    const convertStep = (projectStep: ProjectStep): Step => {
        const getStatus = () => {
            if (completedStepNumbers.includes(projectStep.number)) {
                return 'done';
            }

            if (projectStep.number > nextStepNumber) {
                return 'pending';
            }

            if (projectStep.number < nextStepNumber && projectStep.trigger === undefined) {
                return 'skipped';
            }

            return 'active';
        };

        const getIsBlocked = () => {
            if (projectStep.trigger === undefined && !projectStep.allowClientConfirmation) {
                return true;
            }
            if (projectStep.trigger === 'Assets Approved') {
                // check to see if approval is blocked by unposted assets
                let assetCount = 0, approvedAssetCount = 0;
                if (projectStep.assetType === 'Design') {
                    assetCount = project.designCount || 0;
                    approvedAssetCount = project.approvedDesignCount || 0;
                } else if (projectStep.assetType === 'Material Selection') {
                    assetCount = project.materialSelectionCount || 0;
                    approvedAssetCount = project.approvedMaterialSelectionCount || 0;
                } else if (projectStep.assetType === 'Bid') {
                    assetCount = project.bidCount || 0;
                    approvedAssetCount = project.approvedBidCount || 0;
                }

                if (approvedAssetCount === assetCount) {
                    return true;
                }
            }

            return false;
        };

        const getActionButtonProps = () => {
            switch (projectStep.trigger) {
                case 'Questionnaire Submitted':
                    return {
                        text: 'Complete Online',
                        onClick: () => goToTab('Specifications')
                    };
                case 'Assets Approved':
                    return {
                        text: 'Review ' + plural(projectStep.assetType!),
                        onClick: () => {
                            goToTab({
                                'Design': 'Designs',
                                'Material Selection': 'Materials Selections',
                                'Bid': 'Bids'
                            }[projectStep.assetType!]);
                        }
                    }
                case undefined:
                    return {
                        text: 'Mark Complete',
                        onClick: () => {
                            let _project = { ...project };
                            _project.completedStepIds = [..._project.completedStepIds, projectStep.id];
                            saveProject(_project).then(setProject);
                        }
                    };
                default:
                    return undefined;
            }
        }

        return {
            number: projectStep.number,
            name: projectStep.name,
            description: projectStep.description,
            status: getStatus(),
            isBlocked: getIsBlocked(),
            actionButton: getActionButtonProps()
        };
    }

    return <>
        <h1>Welcome, {user?.firstName}!</h1>
        <p>
            Thank you for choosing Blount Designs. We are very excited to be working with you on your project. We will work with you to make sure your vision for your outdoor space is realized!
        </p>
        <p>
            This portal is designed to help us coordinate your project and keep you up-to-date. Please reach out with any questions you may have.
        </p>
        <div className='box steps inverse mt-4'>
            <div className="box-wrapper">
                <h2 className='my-2 text-center'>
                    The Process
                </h2>
                <Stepper
                    steps={projectTemplate.steps.map(convertStep)} />
            </div>
        </div>
    </>
};
