import { useEffect, useState } from 'react';
import './login.scss';
import { useNavigate } from 'react-router';
import { forgotPassword, getCurrentUser, login } from '../../api';
import { useAppContext, useDialog } from '../../hooks';

export const Login = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const { user, setUser } = useAppContext();

    const navigate = useNavigate();
    const { alert, prompt } = useDialog();

    const onLogin = () => {
        login({userName, password}).then(result => {
            if (result.success) {
                getCurrentUser().then(setUser);
            } else {
                alert(result.message!);
            }
        });
    };

    const onForgotPassword = async () => {
        const email = await prompt({ message: 'What is your email address?', defaultValue: userName });
        if (email) {
            forgotPassword(email);
            alert('Thank you! If the email you provided matches an account in our system, a password reset link will be emailed to that address shortly.');
        } else {
            alert('No email address provided. Please try again.');
        }
    };

    useEffect(() => {
        if (user) {
            const redirect = sessionStorage['redirect'];
            delete sessionStorage['redirect'];
            navigate(redirect || '/');
        }
    }, [user]);

    return <div className='login-wrapper'>
        <div className='logo'>
            BLOUNT | DESIGNS
        </div>
        <div className='login-form'>
            <div className="form-group">
                <label htmlFor="userName">
                    Email Address
                </label>
                <input type="userName"
                    className="form-control"
                    id="userName"
                    value={userName}
                    onChange={e => setUserName(e.target.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="password">
                    Password
                </label>
                <input type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)} />
            </div>
            <div className='mt-5'>
                <button type="submit"
                    className="btn btn-primary btn-lg col-12"
                    onClick={() => onLogin()}>
                    Login
                </button>
            </div>
            <div className='text-center mt-3'>
                <button onClick={() => onForgotPassword()} className='btn btn-link'>Forgot Password</button>
            </div>
        </div>
    </div>
};