import { UserMessage } from "./models";

export interface MessageBoxProps {
    message: UserMessage
}

export const MessageBox = ({ message }: MessageBoxProps) =>
    <div className='box inverse text-center mb-5 pb-1'>
        {message.title && <h2>{message.title}</h2>}
        <p>{message.text}</p>
    </div>;