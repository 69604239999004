import ReactDOM from 'react-dom/client';
import { useState } from 'react';
import { Modal } from '../components';

export interface AlertOptions {
    message: string;
}

export interface ConfirmOptions {
    message: string;
}

export interface PromptOptions {
    message: string;
    defaultValue?: string;
    multiline?: boolean;
    allowCancel?: boolean;
}

export const useDialog = () => {
    interface DialogProps {
        onConfirm?: (value?: string) => void;
        onCancel?: () => void;
        onEscape?: () => void;
    }

    const Alert = ({ message, onConfirm }: AlertOptions & DialogProps) => {
        return <Modal size='sm' onConfirm={onConfirm}>
            {message}
        </Modal>
    };

    const Confirm = ({ message, onConfirm, onCancel }: ConfirmOptions & DialogProps) => {
        return <Modal size='sm' onConfirm={onConfirm} onCancel={onCancel}>
            {message}
        </Modal>
    };

    const Prompt = ({ message, defaultValue, multiline, onConfirm, onCancel }: PromptOptions & DialogProps) => {
        const [value, setValue] = useState(defaultValue || '');

        return <Modal size='sm' onConfirm={() => onConfirm!(value)} onCancel={onCancel}>
            <div className='form-group'>
                <label htmlFor='dialog-prompt'>{message}</label>
                {
                    multiline &&
                    <textarea id="dialog-prompt" className='form-control'
                        value={value}
                        onChange={(e) => setValue(e.target.value)} />
                }
                {
                    !multiline &&
                    <input id="dialog-prompt" type='text'
                        className='form-control'
                        value={value}
                        onChange={(e) => setValue(e.target.value)} />
                }
            </div>
        </Modal>
    };

    const getRoot = () => {
        let dialog = document.getElementById('dialog');
        if (!dialog) {
            dialog = document.createElement('div');
            dialog.id = 'dialog';
            document.body.appendChild(dialog);
        }
        return ReactDOM.createRoot(dialog);
    };

    return {
        alert: (alert: string | AlertOptions): Promise<void> => {
            const alertOptions = (typeof alert === 'string' ? { message: alert } : alert) as AlertOptions;
            return new Promise((resolve) => {
                const root = getRoot();
                root.render(<Alert {...alertOptions}
                    onConfirm={() => {
                        root.unmount();
                        resolve();
                    }}
                />);
            });
        },
        confirm: (confirm: string | ConfirmOptions): Promise<boolean> => {
            const confirmOptions = (typeof confirm === 'string' ? { message: confirm } : confirm) as ConfirmOptions;
            return new Promise((resolve) => {
                const root = getRoot();
                root.render(<Confirm {...confirmOptions}
                    onConfirm={() => {
                        root.unmount();
                        resolve(true);
                    }}
                    onCancel={() => {
                        root.unmount();
                        resolve(false);
                    }}
                />);
            });
        },
        prompt: (prompt: string | PromptOptions): Promise<string | undefined> => {
            const promptOptions = (typeof prompt === 'string' ? { message: prompt } : prompt) as PromptOptions;
            return new Promise((resolve) => {
                const root = getRoot();
                root.render(<Prompt {...promptOptions}
                    onConfirm={(value) => {
                        root.unmount();
                        resolve(value);
                    }}
                    onCancel={promptOptions.allowCancel ? () => {
                        root.unmount();
                        resolve(undefined);
                    } : undefined}
                />);
            });
        }
    };
};