import { createContext, useEffect, useState } from "react";
import { User } from "./models";

export interface AppContextProps {
    user?: User;
    setUser: (user: User | undefined) => void;
    isAuthenticated: () => boolean;
    logout: () => void;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | undefined>(sessionStorage['user'] ? JSON.parse(sessionStorage['user']) : undefined);

  useEffect(() => {
    if (user) {
      sessionStorage['user'] = JSON.stringify(user);
    } else {
      sessionStorage.removeItem('user');
    }
  }, [user]);

  const props: AppContextProps = {
    user,
    setUser,
    isAuthenticated: () => !!localStorage['accessToken'],
    logout: () => {
      localStorage.removeItem('accessToken');
      setUser(undefined);
    }
  };

  return (
    <AppContext.Provider value={props}>
      {children}
    </AppContext.Provider>
  );
};