import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AcceptInvite, AppWrapper, ConfigEditor, IntakeForm, Landing, Login, ProjectList, ProjectTemplateList, ProjectViewer, QuestionSetEditor, QuestionSetList, ResetPassword } from "./components";
import { Protected } from "./util";

export const AppRouter = () => <BrowserRouter>
    <Routes>
        <Route path="login" element={<Login />} />
        <Route path="accept-invite" element={<AcceptInvite />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="update-password" element={<ResetPassword />} />
        <Route path="intake-form" element={<IntakeForm />} />
        <Route path="" element={<Protected element={<AppWrapper />} />}>
            <Route path="" element={<Landing />} />
            <Route path=":projectKey" element={<ProjectViewer />} />
            <Route path="admin" element={<Protected roles={['admin']} />}>
                <Route path="config" element={<ConfigEditor />} />
                <Route path="projects/" element={<ProjectList />} />
                <Route path="projects/:projectKey" element={<ProjectViewer />} />
                <Route path="project-templates" element={<ProjectTemplateList />} />
                <Route path="question-sets" element={<QuestionSetList />} />
                <Route path="question-sets/new" element={<QuestionSetEditor />} />
                <Route path="question-sets/:questionSetKey" element={<QuestionSetEditor />} />
            </Route>
        </Route>
    </Routes>
</BrowserRouter>