import './stepper.scss';

export interface Step {
    number: number;
    name: string;
    description?: string;
    status: 'done' | 'skipped' | 'active' | 'pending';
    isBlocked?: boolean;
    actionButton?: {
        text: string;
        onClick: () => void;
    };
}

export interface StepperProps {
    steps: Step[]
}

export const Stepper = ({ steps }: StepperProps) => {
    return <div className="stepper">
        {steps.map(step =>
            <div key={step.number} className={`step ${step.status}-step`}>
                <div className="step-bar-left"></div>
                <div className="step-circle"><span>{step.number}</span></div>
                <h4 className="step-title">{step.name}</h4>
                {
                    step.description &&
                    <p className="step-description">{step.description}</p>
                }
                <div className='step-status'>
                    {
                        step.status === 'done' &&
                        <span className='text-success fw-bold text-sm text-uppercase'>
                            <i className="fa fa-check"></i>
                        </span>
                    }
                    {
                        step.status === 'active' &&
                        <>
                            {
                                (step.actionButton && !step.isBlocked) &&
                                <button type="button"
                                    className='btn btn-sm btn-primary'
                                    onClick={() => step.actionButton?.onClick()}>
                                    {step.actionButton.text}
                                </button>
                            }
                            {
                                (!step.actionButton || step.isBlocked) &&
                                <span className='text-info fw-bold text-sm text-uppercase'>
                                    <i className="fa-regular fa-clock me-1"></i>
                                    { step.isBlocked ? 'Waiting on us' : 'In Progress' }
                                </span>
                            }
                        </>
                    }
                </div>
                <div className="step-bar-right"></div>
            </div>
        )}
    </div>;
};