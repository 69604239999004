export interface AlertProps {
    type?: 'info' | 'warning' | 'danger' | 'success';
    text?: string;
    children?: React.ReactNode;
}

export const Alert = ({ type = "warning", text, children }: AlertProps) => {
    const iconLegend = {
        info: 'fa-info-circle',
        warning: 'fa fa-exclamation-triangle',
        danger: 'fa-exclamation-circle',
        success: 'fa-check-circle'
    };

    return <div className={`alert alert-${type} d-flex justify-content-start align-items-center`}>
        <i className={`fa fa-3x ${iconLegend[type]} me-3`}></i>
        <div className="lead fs-4">{text || children}</div>
    </div>;
};