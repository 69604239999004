import React, { useEffect, useState } from 'react';

interface AccordionProps {
    defaultIndex?: number;
    children: React.ReactNode;
};

export const Accordion = ({ defaultIndex, children }: AccordionProps) => {
    const [activeIndex, setActiveIndex] = useState<number | undefined>(defaultIndex);

    const handleItemClick = (index: number) => {
        setActiveIndex(index === activeIndex ? undefined : index);
    };

    useEffect(() => {
        setActiveIndex(defaultIndex);
    }, [defaultIndex, React.Children.count(children)]);

    return (
        <div className="accordion" id="accordion">
            {React.Children.map(children, (child, index) => {
                if (React.isValidElement(child)) {
                    const { title } = child.props as AccordionItemProps;
                    const isActive = index === activeIndex;
                    return (
                        <div className={`accordion-item ${isActive ? 'active' : ''}`} key={index}>
                            <div className="accordion-header" id={`heading-${index}`}>
                                <button type="button"
                                    className={`bg-light accordion-button ${isActive ? '' : 'collapsed'}`}
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-${index}`}
                                    onClick={() => handleItemClick(index)}>
                                    {title}
                                </button>
                            </div>
                            <div
                                id={`collapse-${index}`}
                                className={`accordion-collapse collapse ${isActive ? 'show' : ''}`}
                                data-bs-parent="#accordion">
                                <div className="accordion-body">{child.props.children}</div>
                            </div>
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
};

interface AccordionItemProps {
    title: string;
    children: React.ReactNode;
};

export const AccordionItem = ({ children }: AccordionItemProps) => {
    return <div>{children}</div>;
};
