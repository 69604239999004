import { AppContextProvider } from './AppContext';
import { AppLoader } from './AppLoader';
import { AppRouter } from './AppRouter';

export const App = () => {
  return <AppContextProvider>
    <AppLoader>
      <AppRouter />
    </AppLoader>
  </AppContextProvider>;
}