export interface ImageCarouselProps {
    images: {
        src: string;
        caption: string;
    }[];
}

export const ImageCarousel = ({ images }: ImageCarouselProps) => {
    return <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-indicators">
            {
                images.map((img, i) => (
                    <button key={i} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={i} className={i === 0 ? 'active' : ''}></button>
                ))
            }
        </div>
        <div className="carousel-inner">
            {
                images.map((img, i) =>
                    <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`}>
                        <img src={img.src} className="d-block w-100" alt={img.caption} />
                        <div className="carousel-caption d-none d-md-block">
                            <h5>{img.caption}</h5>
                        </div>
                    </div>
                )
            }
        </div>
        <button type="button" className="carousel-control-prev" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button type="button" className="carousel-control-next" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>;
};