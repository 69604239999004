import { Navigate } from 'react-router';
import { useAppContext } from '../../hooks';
import { Alert, Card } from '../common';
import './landing.scss';
import { Link } from 'react-router-dom';
import { ProjectInfo } from '../../models';

export const Landing = () => {
  const { user } = useAppContext();

  if (user?.roles?.includes('admin')) {
    return <Navigate to='/admin/projects' replace={true} />;
  } else if (user?.projects?.length === 1) {
    return <Navigate to={`${user.projects[0].key}`} replace={true} />;
  } else if (!user?.projects?.length) {
    return <Alert>
      Uh oh! It looks like you are not currently assigned to any projects.<br />
      Please <a href="mailto:suppor@blountdesigns.com">contact us</a> for assistance.
    </Alert>;
  } else {
    return <ProjectSelector projects={user?.projects!} />
  }
}

const ProjectSelector = ({ projects }: { projects: ProjectInfo[] }) => {
  const { user } = useAppContext();
  return <div>
    <h1>Welcome, {user?.firstName}!</h1>
    <p>
      It looks like you are part of more than one project! Please select the project you would like to manage below. You may also use the menu above to navigate between projects.
    </p>
    <div className='row'>
      {
        projects?.map(project => {
          return <div key={project.id} className='col-3'>
            <Card title={project.description} iconClass={'fa fa-folder-open'}>
              <div className="row">
                <div className="col-8">
                  {project.address.street}<br />
                  {project.address.city}, {project.address.state} {project.address.postal}
                </div>
                <div className="col-4 d-flex justify-content-end align-items-end">
                  <Link to={`/${project.key}`} className='btn btn-outline-primary btn-block btn-sm'>
                    Open
                  </Link>
                </div>
              </div>
            </Card>
          </div>
        })
      }
    </div>
  </div>
};