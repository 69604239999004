export interface CardProps {
    title?: string;
    iconClass?: string;
    badge?: string;
    children?: React.ReactNode;
}

export const Card = ({ title, iconClass, badge, children }: CardProps) => <div className="card mb-4">
    {title &&
        <div className="card-header">
            {
                badge && <span className="float-end">
                    <span className="badge bg-secondary">{badge}</span>
                </span>
            }
            {iconClass && <i className={iconClass + ' me-2'}></i>}
            {title}
            <div className="clearfix"></div>
        </div>
    }
    <div className="card-body">
        {children}
    </div>
</div>;