import { Navigate, Outlet } from 'react-router-dom';
import { useAppContext } from '../hooks';

export interface ProtectedProps {
    element?: JSX.Element;
    roles?: string[];
}

export const Protected = ({ element, roles }: ProtectedProps) => {
    const { user } = useAppContext();
    
    if (!user) {
        if (window.location.pathname !== '/login') {
            sessionStorage['redirect'] = window.location.pathname;
        }
        return <Navigate to={'/login'} />;
    } else if (roles && !user.roles?.some((r: string) => roles.includes(r))) {
        return <Navigate to={'/'} />;
    }

    return element || <Outlet />;
  };