import { useEffect, useState } from "react";
import {
    ElementType,
    ElementConfig,
    HeaderElementConfig,
    TextElementConfig,
    ImageSetElementConfig,
    TextEntryElementConfig,
    RadioButtonsElementConfig,
    DropdownElementConfig,
    CheckboxesElementConfig,
    ToggleSwitchElementConfig,
    ImageSelectorElementConfig,
    FileUploadElementConfig,
    OptionsElementConfig,
    ImagesElementConfig,
    FormElementConfig
} from "../../../models";
import { Modal } from "../../common/modal";

import './elementConfigEditor.scss';

export interface ElementConfigEditorProps {
    elementType: ElementType;
    elementName: string;
    config?: ElementConfig;
    onConfigUpdated: (config: ElementConfig) => void;
    onCanceled: () => void;
}

export const ElementConfigEditor = ({ elementType, elementName, config, onConfigUpdated, onCanceled }: ElementConfigEditorProps) => {
    const [tempConfig, setTempConfig] = useState<ElementConfig | undefined>(config || getDefaultConfig(elementType));
    const elementConfigEditor = getElementConfigEditor(elementType, tempConfig, setTempConfig);

    return <Modal title={`Configure ${elementName}`}
        onConfirm={() => onConfigUpdated(tempConfig!)}
        onCancel={() => onCanceled()}>
        {elementConfigEditor}
    </Modal>;
};

const getDefaultConfig = (elementType: ElementType): ElementConfig | undefined => {
    if (elementType === 'Header') {
        return {
            level: 1,
            text: ''
        } as HeaderElementConfig;
    } else if (elementType === 'Text') {
        return {
            text: ''
        } as TextElementConfig;
    } else if (elementType === 'ImageSet') {
        return {
            images: []
        } as ImageSetElementConfig;
    } else if (elementType === 'Divider') {
        return undefined;
    } else if (elementType === 'TextEntry') {
        return {
            prompt: '',
            multiline: false
        } as TextEntryElementConfig;
    } else if (elementType === 'RadioButtons') {
        return {
            prompt: '',
            options: [],
            columns: 1
        } as RadioButtonsElementConfig;
    } else if (elementType === 'Dropdown') {
        return {
            prompt: '',
            options: []
        } as DropdownElementConfig;
    } else if (elementType === 'Checkboxes') {
        return {
            prompt: '',
            options: [],
            columns: 1
        } as CheckboxesElementConfig;
    } else if (elementType === 'ToggleSwitch') {
        return {
            prompt: ''
        } as ToggleSwitchElementConfig;
    } else if (elementType === 'ImageSelector') {
        return {
            prompt: '',
            images: [],
            allowMultipleSelection: false
        } as ImageSelectorElementConfig;
    } else if (elementType === 'FileUpload') {
        return {
            prompt: '',
            acceptedFileTypes: '',
            allowMultipleSelection: false
        } as FileUploadElementConfig;
    } else {
        throw new Error(`Unknown element type: ${elementType}`);
    }
};

const getElementConfigEditor = (elementType: ElementType, config: ElementConfig | undefined, onConfigUpdated: (config: ElementConfig) => void) => {
    const editorMap: Record<ElementType, any> = {
        'Header': HeaderElementConfigEditor,
        'Text': TextElementConfigEditor,
        'ImageSet': ImageSetElementConfigEditor,
        'Divider': undefined,
        'TextEntry': TextEntryElementConfigEditor,
        'RadioButtons': RadioButtonsElementConfigEditor,
        'Dropdown': DropdownElementConfigEditor,
        'Checkboxes': CheckboxesElementConfigEditor,
        'ToggleSwitch': ToggleSwitchElementConfigEditor,
        'ImageSelector': ImageSelectorElementConfigEditor,
        'FileUpload': FileUploadElementConfigEditor,
    };

    const Editor = editorMap[elementType];
    return Editor && <Editor config={config as any} onConfigUpdated={onConfigUpdated} />;
};

interface ConfigEditorProps<T extends ElementConfig> {
    config: T;
    onConfigUpdated: (config: T) => void;
}

// --- Shared Components --- //

// Prompt

const Prompt = ({ config, onConfigUpdated }: ConfigEditorProps<FormElementConfig>) => <div className="form-group">
    <label>Prompt</label>
    <input type="text"
        className="form-control"
        value={config.prompt}
        onChange={e => onConfigUpdated({ ...config, prompt: e.target.value })} />
</div>;

const Options = ({ config, onConfigUpdated }: ConfigEditorProps<OptionsElementConfig>) => {
    const [focusFieldId, setFocusFieldId] = useState<string | undefined>(undefined);
    const [batchEntry, setBatchEntry] = useState<boolean>(false);
    useEffect(() => {
        if (focusFieldId) {
            let lastOption = document.getElementById(focusFieldId);
            lastOption?.focus();
            setFocusFieldId(undefined);
        }
    }, [focusFieldId]);

    return <div className="form-group">
        <div className="float-end">
            <label className="text-sm" onClick={() => setBatchEntry(!batchEntry)}>
                <i className={batchEntry ? "fa fa-toggle-on me-1" : "fa fa-toggle-off me-1"}></i>
                Batch Edit
            </label>
        </div>
        <label>Options</label>
        <div className="clearfix"></div>
        {
            !batchEntry &&
            <div className="options-container">
                {config.options.map((option, index) => <div key={index}>
                    <div className="input-group mb-2">
                        <input type="text"
                            id={'option-field-' + index}
                            className="form-control"
                            value={option as string}
                            onChange={e => onConfigUpdated({
                                ...config,
                                options: config.options.map((option, i) => i === index
                                    ? e.target.value
                                    : option)
                            })} />
                        <button type="button" className="input-group-text"
                            onClick={() => onConfigUpdated({ ...config, options: config.options.filter((option, i) => i !== index) })}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                </div>)}
                {config.options.length > 0 && <div className="pb-2"></div>}
                <div className="text-center">
                    <button type="button"
                        onClick={() => {
                            onConfigUpdated({ ...config, options: [...config.options, ''] });
                            setFocusFieldId('option-field-' + (config.options.length));
                        }}
                        className="btn btn-outline-primary btn-sm">
                        Add Option
                    </button>
                </div>
            </div>
        }
        {
            batchEntry &&
            <>
                <textarea className="form-control"
                    rows={10}
                    value={config.options.join('\n')}
                    onChange={e => onConfigUpdated({ ...config, options: e.target.value.split('\n') })} />
            </>
        }
    </div>;
}

const Images = ({ config, onConfigUpdated, label }: ConfigEditorProps<ImagesElementConfig> & { label?: string }) => {
    const uploadImage = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = true;

        input.onchange = (e: any) => {
            const files = e.target.files;
            if (files && files.length > 0) {
                const newImages: any[] = [];

                for (let i = 0; i < files.length; i++) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        newImages.push({ src: event.target?.result, caption: '' });

                        if (newImages.length === files.length) {
                            onConfigUpdated({ ...config, images: [...config.images, ...newImages] });
                        }
                    };

                    reader.readAsDataURL(files[i]);
                }
            }
        };

        input.click();
    };

    return <div className="form-group">
        {label && <label>{label}</label>}
        <div className="images-container">
            <div className="row">
                {config.images.map((image, index) =>
                    <div key={index} className="col-6">
                        <div className="text-center mb-2 position-relative">
                            <button type="button"
                                className="btn btn-sm position-absolute text-danger"
                                style={{ top: '0', right: '0' }}
                                onClick={() => onConfigUpdated({ ...config, images: config.images.filter((image, i) => i !== index) })}>
                                <i className="fa fa-trash"></i>
                            </button>
                            <img src={image.src} alt={image.caption} style={{ maxHeight: '150px', maxWidth: '100%' }} />
                        </div>
                        <div className="form-group">
                            <input type="text"
                                placeholder="Caption"
                                className="form-control"
                                value={image.caption}
                                onChange={e => onConfigUpdated({
                                    ...config,
                                    images: config.images.map((image, i) => i === index
                                        ? { ...image, caption: e.target.value }
                                        : image)
                                })} />
                        </div>
                    </div>)}
            </div>
            {config.images.length > 0 && <div className="pb-2"></div>}
            <div className="text-center">
                <button type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={uploadImage}>
                    Add Image
                </button>
            </div>
        </div>
    </div>;
}

// --- Element Config Editor Implementations --- //

const HeaderElementConfigEditor = ({ config, onConfigUpdated }
    : ConfigEditorProps<HeaderElementConfig>) =>
    <div>
        <div className="form-group">
            <label>Level</label>
            <select value={config.level}
                className="form-control"
                onChange={e => onConfigUpdated({ ...config, level: +e.target.value as 1 | 2 | 3 | 4 | 5 | 6 })}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
            </select>
        </div>
        <div className="form-group">
            <label>Text</label>
            <input type="text"
                className="form-control"
                value={config.text}
                onChange={e => onConfigUpdated({ ...config, text: e.target.value })} />
        </div>
    </div>

const TextElementConfigEditor = ({ config, onConfigUpdated }
    : ConfigEditorProps<TextElementConfig>) =>
    <div className="form-group">
        <label>Text</label>
        <textarea
            className="form-control"
            rows={10}
            value={config.text}
            onChange={e => onConfigUpdated({ ...config, text: e.target.value })} />
    </div>;

const ImageSetElementConfigEditor = ({ config, onConfigUpdated }
    : ConfigEditorProps<ImageSetElementConfig>) =>
    <div>
        <Images config={config} onConfigUpdated={x => onConfigUpdated(x)} />
    </div>;

const TextEntryElementConfigEditor = ({ config, onConfigUpdated }
    : ConfigEditorProps<TextEntryElementConfig>) =>
    <div>
        <Prompt config={config} onConfigUpdated={onConfigUpdated} />
        <div className="form-group">
            <label>
                <input type="checkbox"
                    checked={config.multiline}
                    onChange={e => onConfigUpdated({ ...config, multiline: e.target.checked })} />
                Long Entry (Multiple lines)
            </label>
        </div>
    </div>;

const RadioButtonsElementConfigEditor = ({ config, onConfigUpdated }
    : ConfigEditorProps<RadioButtonsElementConfig>) =>
    <div>
        <Prompt config={config} onConfigUpdated={x => onConfigUpdated(x as RadioButtonsElementConfig)} />
        <Options config={config} onConfigUpdated={x => onConfigUpdated(x as RadioButtonsElementConfig)} />
        <div className="form-group">
            <label>Columns</label>
            <select value={config.columns}
                className="form-control"
                onChange={e => onConfigUpdated({ ...config, columns: +e.target.value as number })}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
            </select>
        </div>
    </div>;

const DropdownElementConfigEditor = ({ config, onConfigUpdated }
    : ConfigEditorProps<DropdownElementConfig>) =>
    <div>
        <Prompt config={config} onConfigUpdated={x => onConfigUpdated(x as DropdownElementConfig)} />
        <Options config={config} onConfigUpdated={x => onConfigUpdated(x)} />
    </div>;

const CheckboxesElementConfigEditor = ({ config, onConfigUpdated }
    : ConfigEditorProps<CheckboxesElementConfig>) =>
    <div>
        <Prompt config={config} onConfigUpdated={x => onConfigUpdated(x as CheckboxesElementConfig)} />
        <Options config={config} onConfigUpdated={x => onConfigUpdated(x as CheckboxesElementConfig)} />
        <div className="form-group">
            <label>Columns</label>
            <select value={config.columns}
                className="form-control"
                onChange={e => onConfigUpdated({ ...config, columns: +e.target.value as number })}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
            </select>
        </div>
    </div>;

const ToggleSwitchElementConfigEditor = ({ config, onConfigUpdated }
    : ConfigEditorProps<ToggleSwitchElementConfig>) =>
    <div>
        <Prompt config={config} onConfigUpdated={x => onConfigUpdated(x)} />
    </div>;

const ImageSelectorElementConfigEditor = ({ config, onConfigUpdated }
    : ConfigEditorProps<ImageSelectorElementConfig>) =>
    <div>
        <Prompt config={config} onConfigUpdated={x => onConfigUpdated(x as ImageSelectorElementConfig)} />
        <Images label="Options" config={config} onConfigUpdated={x => onConfigUpdated(x as ImageSelectorElementConfig)} />
        <div className="form-group">
            <label>
                <input type="checkbox"
                    checked={config.allowMultipleSelection}
                    onChange={e => onConfigUpdated({ ...config, allowMultipleSelection: e.target.checked })} />
                Allow Multiple Selection
            </label>
        </div>
    </div>;

const FileUploadElementConfigEditor = ({ config, onConfigUpdated }
    : ConfigEditorProps<FileUploadElementConfig>) => {
    const suggestedFileTypes = [
        { label: 'Images Only', value: 'image/*' },
        { label: 'PDFs Only', value: 'application/pdf' }
    ];

    const specifyFileTypes = !suggestedFileTypes.map(x => x.value).includes(config.acceptedFileTypes);

    return <div>
        <Prompt config={config} onConfigUpdated={x => onConfigUpdated(x as FileUploadElementConfig)} />
        <div className="form-group">
            <div className="radio">
                {
                    suggestedFileTypes.map(x => <label className="me-4">
                        <input type="radio"
                            checked={config.acceptedFileTypes === x.value}
                            onChange={() => onConfigUpdated({ ...config, acceptedFileTypes: x.value })} />
                        {x.label}
                    </label>)
                }
                <label className="me-4">
                    <input type="radio"
                        checked={specifyFileTypes}
                        onChange={() => onConfigUpdated({ ...config, acceptedFileTypes: '' })} />
                    Specify File Types...
                </label>
            </div>
        </div>
        {
            specifyFileTypes && <div className="form-group">
                <label>
                    Accepted File Types
                    <span>(comma seperated)</span>
                </label>
                <textarea
                    className="form-control"
                    value={config.acceptedFileTypes}
                    onChange={e => onConfigUpdated({ ...config, acceptedFileTypes: e.target.value })} />
            </div>
        }
        <div className="form-group">
            <label>
                <input type="checkbox"
                    checked={config.allowMultipleSelection}
                    onChange={e => onConfigUpdated({ ...config, allowMultipleSelection: e.target.checked })} />
                Allow Multiple Selection
            </label>
        </div>
        {
            config.allowMultipleSelection &&
            <div className="form-group">
                <label>Group Name
                    <span>(used for group files)</span>
                </label>
                <input type="text"
                    className="form-control"
                    value={config.name}
                    onChange={e => onConfigUpdated({ ...config, name: e.target.value })} />
            </div>
        }
    </div>;
}