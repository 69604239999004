interface DrawerProps {
    onCancel?: () => void;
    onConfirm?: () => void;
    onEscape?: () => void;
    title?: string;
    confirmText?: string;
    size?: 'sm' | 'md' | 'lg' | 'xl';
    children: React.ReactNode;
}

export const Drawer = ({ onConfirm, onCancel, onEscape, confirmText = 'OK', title, size, children }: DrawerProps) => {
    return (
        <div>
            <div className="offcanvas-backdrop fade show" onClick={onEscape ? onEscape : () => { }}></div>
            <div className={"offcanvas offcanvas-end show" + (!!size ? ' offcanvas-end-' + size : '')}
                data-bs-backdrop="static"
                tabIndex={-1}>
                {
                    (title || onCancel) &&
                    <div className="offcanvas-header bg-dark text-white">
                        <h5 className="offcanvas-title">
                            {title}
                        </h5>
                        {onEscape && <button type="button" className="btn btn-close" onClick={onEscape}></button>}
                    </div>
                }
                <div className="offcanvas-body">
                    {children}
                </div>
                {
                    (onConfirm || onCancel) &&
                    <div className="offcanvas-bottom bg-light">
                        {onCancel && <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>}
                        {onConfirm && <button type="button" className="btn btn-primary" onClick={onConfirm}>{confirmText}</button>}
                    </div>
                }
            </div>
        </div>
    );
};