import { useEffect, useState } from "react";
import { getConfiguration } from "../../api";
import hljs from 'highlight.js';
import './configEditor.scss';

export const ConfigEditor = () => {
    const [configuration, setConfiguration] = useState<any>([]);

    useEffect(() => {
        getConfiguration().then(x => {
            setConfiguration(x);
            setTimeout(() => {
                hljs.highlightAll();
            });
        });
    }, []);

    return <>
        <h1>
            <i className="fa-brands fa-google-drive me-2"></i>
            Google Drive Configuration
        </h1>
        <p className="alert alert-danger mb-4 p-4">
            <i className="fa fa-warning me-2"></i>
            <strong>Warning!</strong> Invalid modifications to this configuration could have unintended side effects and/or cause the application to stop working. Proceed with caution!
        </p>
        <pre>
            <code contentEditable
                spellCheck={false}
                className="json form-control">
                {JSON.stringify(configuration, null, 2)}
            </code>
        </pre>
        <button className="btn btn-lg btn-primary col-12">
            Save
        </button>
    </>;
};